<template>
    <a-card>
        <a-page-header
            title="青鸟线索通表单模板管理"
        />

        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="模板类型">
                        <a-select
                            v-model="form.formTemplateType"
                            placeholder="请选择类型"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in stencilList"
                                :key="item.code"
                            >
                                {{ item.desc }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="状态">
                        <a-select
                            v-model="form.templateStatus"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option key="2">有效</a-select-option>
                            <a-select-option key="1">停用</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                        <a-button type="primary" @click="jumpDetailPage(0)">新建模板</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <div class="flex-end mt-20">新增模板每30分钟更新一次给所有广告主</div>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :pagination='false'
            class="mt-20"
            @change="handleTableChange"
        >
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <a class="link" @click="updateState(record)">{{ record.templateStatus == 1 ? '有效' : '停用' }}</a>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <div class="mt-20">
            <div>PS：</div>
            <div>新建表单模板的状态默认为“停用”，如需改为“有效”，请先确定相同模版类型无“有效”状态的模板</div>
        </div>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '状态',
                    customRender: (text, row) => {
                        let txt = <div class="red">停用</div>
                        return row.templateStatus == 2 ? '有效' : row.templateStatus == 1 ? txt : '-'
                    }
                },
                {
                    align: 'center',
                    title: '同步率',
                    dataIndex: 'syncRate',
                    sorter: true,
                    customRender(text) {
                        return text + '%' || '-'
                    }
                },
                {
                    align: 'center',
                    title: '模板类型',
                    dataIndex: 'formTemplateTypeDesc',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '模板名称',
                    dataIndex: 'templateName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '按钮名称',
                    dataIndex: 'buttonText',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '姓名标题',
                    dataIndex: 'nameLabel',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '电话标题',
                    dataIndex: 'telLabel',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '城市标题',
                    dataIndex: 'cityLabel',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '有效性验证',
                    customRender: (text, row) => {
                        let txt = '-'
                        switch(Number(row.validityType)) {
                            case 1:
                                txt = '有效性优先'
                                break;
                            case 2:
                                txt = '线索量优先'
                                break;
                            case 3:
                                txt = '全不出'
                                break;
                            case 4:
                                txt = '智能验证'
                                break;
                            case 5:
                                txt = '全出'
                                break;
                        }
                        return txt
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
                {
                    align: 'center',
                    title: '创建时间',
                    dataIndex: 'ctime',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            stencilList: [],
        }
    },
    created() {
        this.getStencilList()
        this.getDataList()
    },
    methods: {
        getStencilList() {
            this.$api.core.materialManage.getStencilList().then(res => {
                if(res.code == 200) {
                    this.stencilList = res.data
                } else {
                    this.stencilList = []
                    console.error(`获取模版列表失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handleTableChange(pagination, filters, sorter) {
            const pager = { ...this.pagination };
            pager.current = pagination.current;
            this.pagination = pager;
            this.getDataList({
                size: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                syncRateOrder: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
                ...filters,
            });
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList(data = {}) {
            this.loading = true
            const _form = this.form
            let params = {
                ..._form,
                ...data,
                page: this.pagination.current,
                size: this.pagination.pageSize,
            }
            this.$api.core.materialManage.getFormTemplateList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(id) {
            this.$router.push({
                path: `/formManage/detail`,
                query: {
                    id,
                }
            })
        },
        updateState(record) {
            let params = {
                templateStatus: record.templateStatus == 1 ? 2 : 1,
                id: record.id,
            }
            this.$api.core.materialManage.updateFormTemplate(params).then((res) => {
                if(res.code == 200) {
                    this.$message.success('操作成功')
                    this.getDataList()
                } else {
                    if(record.templateStatus == 1) {
                        this.$message.error('操作失败，已有相同类型模板有效中')
                    } else {
                        this.$message.error('操作失败')
                    }
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}

.mt-20 {
    margin-top: 20px;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.link {
    text-decoration: underline;
}
</style>
